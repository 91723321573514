import React, { useEffect, useMemo, useState } from "react";
import AsyncSelect from "react-select/async";
import sisaLogo from "./assests/anestesiologia.png";
import "./App.css";

const MIN_CHARS = 4;
let debounceTimeout = null;
const debounce = (fetchOptions, wait) => (text, callback) => {
  clearTimeout(debounceTimeout);
  if (text && text.length >= MIN_CHARS) {
    debounceTimeout = setTimeout(() => {
      fetchOptions(text.trim(), callback);
    }, wait);
  } else {
    callback();
  }
};

export function formatCurrency(amount) {
  return amount != null && amount !== ""
    ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : amount;
}

const API_URL =  process.env.REACT_APP_ENV === "develop" ? "http://localhost:8060/tarifas/api" : "http://157.245.240.131:8060/tarifas/api";

function App() {
  const [text, setText] = useState("");
  const [searchByDescription, setSearchByDescription] = useState("");
  const [services, setServices] = useState([]);
  const [extras, setExtras] = useState([]);
  const [filterBy, setFilterBy] = useState("description");

  const fetchService = async (text) => {
    if (text) {
      let endpoint = `${API_URL}/services/${text}`;

      try {
        const payload = await fetch(endpoint, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }).then((data) => data.json());
        setServices((prev) => [...prev, ...payload.body.data]);
      } catch (e) {
        console.log("ERROR***", e);
      }
    }
  };

  const onSearch = async () => {
    if (text) {
      fetchService(text);
    }
  };

  const onDescriptionSearch = async (inputValue, callback) => {
    try {
      const payload = await fetch(
        `${API_URL}/services/by_description/${inputValue}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      ).then((data) => data.json());
      const servicesFormat = payload.body.data.map((s) => ({
        label: s.descripcion,
        value: s._id,
      }));
      callback(servicesFormat);
    } catch (e) {
      console.log("Error***", e);
      callback([]);
    }
  };

  const onApplyExtra = (event) => {
    const { name, value } = event.target;
    if (services.length > 0) {
      let higherPrice = 0;
      let idOfHigher;

      services.forEach((service) => {
        if (service.Tarifa > higherPrice) {
          higherPrice = service.Tarifa;
          idOfHigher = service._id;
        }
      });

      const traformServices = services.map((service) => {
        if (service._id === idOfHigher) {
          const extraValue = (service.Tarifa * ((service?.complication ?? 0) + value)) / 100;
          const extraTotal = extraValue + service.Tarifa;

          return {
            ...service,
            extraValue,
            extraTotal,
            porcent: value,
          };
        }
        return service;
      });

      setServices(traformServices);
    }
  };

  const onComplication = (event) => {
    let addPorcent = false;
    if (event.target.checked) {
      if (services.length > 0) {
        let higherPrice = 0;
        let idOfHigher;
  
        services.forEach((service) => {
          if (service.Tarifa > higherPrice) {
            higherPrice = service.Tarifa;
            idOfHigher = service._id;
          }
        });

        const traformServices = services.map((service) => {
          if (service._id === idOfHigher) {
            const extraValue = (service.Tarifa * (parseInt(service?.porcent ?? 0) + 25)) / 100;
            const extraTotal = extraValue + service.Tarifa;
  
            return {
              ...service,
              extraValue,
              extraTotal,
              complication: 25
            };
          }
          return service;
        });
  
        setServices(traformServices);
      }
    } else {
      const traformServices = services.map((service) => {
        if (service.complication) {
          const extraValue = (service.Tarifa * (service?.porcent ?? 0)) / 100;
          const extraTotal = extraValue + service.Tarifa;

          return {
            ...service,
            extraValue,
            extraTotal,
          };
        }
        return service;
      })
      setServices(traformServices);
    }
  };

  const onChangeByDecription = (newValue, { name }) => {
    fetchService(newValue.value);
  };

  const totals = useMemo(() => {
    return services.reduce((acc,crr) => {
      return {
        totalOfTotal: (crr?.extraTotal || crr.Tarifa) + acc.totalOfTotal
      }
    },{
      totalOfTotal: 0
    })
  },[services])

  return (
    <div className="App">
      <div className="logo_container">
        <img src={sisaLogo} alt="logo sda" />
      </div>
      <div className="filterContainer">
        <div>
          <h4>Filtros</h4> {" "}
          <input
            type="radio"
            id="code"
            name="filter"
            value="code"
            checked={filterBy === "code"}
            onClick={(e) => setFilterBy("code")}
          />
            <label htmlFor="code">Código</label>
          <br /> {" "}
          <input
            type="radio"
            id="description"
            name="filter"
            value="description"
            checked={filterBy === "description"}
            onClick={(e) => setFilterBy("description")}
          />
            <label htmlFor="description">Nombre</label>
          <br />
        </div>
        <div className="searchContainer">
          {filterBy === "code" ? (
            <input
              id="code"
              value={text}
              name="search"
              onChange={(e) => setText(e.target.value)}
              type="text"
            />
          ) : (
            <AsyncSelect
              classNamePrefix="service"
              name="byDescriptio"
              loadOptions={debounce(onDescriptionSearch, 700)}
              onChange={onChangeByDecription}
              value={searchByDescription}
              placeholder="Minimo 4 letras"
            />
          )}

          <button name="find" onClick={onSearch}>
            Buscar
          </button>
        </div>
      </div>
      <div className="extra_container">
        <p>
          <b>Aplicar cargos</b>
        </p>

        <select name="extra" id="extra-select" onChange={onApplyExtra}>
          <option value="0">--Favor de seleccionar un cargo si aplica--</option>
          <option value="5">ASA2</option>
          <option value="10">ASA3</option>
          <option value="15">ASA4</option>
          <option value="20">ASA5</option>
          <option value="25">Emergencia</option>
        </select>
        <label>
          <input
            type="checkbox"
            id="cbox1"
            value="first_checkbox"
            onChange={onComplication}
          />{" "}
          Complejidad
        </label>
      </div>
      <p className="message">
        El primer servicio presenta el precio en su totalidad y los
        sub-siguientes con un 50% aplicado{" "}
      </p>
      <table>
        <thead>
          <tr>
            <th>CUPS</th>
            <th>ANESTESIA</th>
            <th>SIMON</th>
            <th>ARS</th>
            <th>Descripcion</th>
            <th>Tarifa</th>
            <th>Extra %</th>
            <th>Complejidad %</th>
            <th>Monto</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {services?.map((service, idx) => {
            return (
              <tr key={idx}>
                <td>{service.codCups}</td>
                <td>{service.codAnestesia}</td>
                <td>{service.codSimon}</td>
                <td>{service.codArs}</td>
                <td>{service.descripcion}</td>
                <td>{formatCurrency(service.Tarifa)}</td>
                <td>{service?.porcent || 0}</td>
                <td>{service?.complication || 0}</td>
                <td>{formatCurrency(service?.extraValue || 0)}</td>
                <td>{formatCurrency((service?.extraTotal || 0))}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th colspan="9">Total</th>
            <td>{formatCurrency(totals.totalOfTotal)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default App;
